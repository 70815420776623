export const BatchStatus = {
  IN_SCREENING: {
    description: "Em Andamento",
    id: 1
  },
  CONCLUDED: {
    description: "Finalizado",
    id: 2
  }
};
