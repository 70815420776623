<template>
  <v-menu transition="slide-x-transition" bottom right>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">
        mdi-dots-horizontal
      </v-icon>
    </template>

    <v-list>
      <v-list-item
        @click="screeningDetail"
        class="d-flex align-center item-dropdown-user"
      >
        <v-list-item-title>
          <v-icon class="item-dropdown-icon">mdi-account</v-icon>
          <span class="pl-2">Ver Registro</span>
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        v-bind:disabled="!isBatchFinished()"
        @click="downloadDocuments"
        class="d-flex align-center item-dropdown-user"
      >
        <v-list-item-title>
          <v-icon class="item-dropdown-icon">mdi-clipboard-edit-outline</v-icon>
          <span class="pl-2">Baixar Relatório</span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import { hasKeys } from "@/modules/core/helpers/objectHelpers";
  import { mapActions } from "vuex";
  import { BatchStatus } from "../../enums/batchStatus.enum";

  export default {
    name: "ScreeningMenuItem",

    props: {
      item: {
        type: Object,
        required: true,
        validator: obj => hasKeys(obj, ["id"])
      }
    },

    methods: {
      ...mapActions("stock/batch", [
        "donwloadBatchConcludedCertificate",
        "downloadBatchReportPdf"
      ]),

      screeningDetail() {
        this.$router.push({
          name: "view-screening",
          params: { id: this.item.id }
        });
      },
      isBatchFinished() {
        return this.item.statusId === BatchStatus.CONCLUDED.id;
      },
      downloadDocuments() {
        this.donwloadBatchConcludedCertificate(this.item.id);
        this.downloadBatchReportPdf(this.item.id);
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .item-dropdown-user:hover {
    background-color: $white-1;

    .item-dropdown-icon {
      color: $primary-color-2;
    }
  }
</style>
