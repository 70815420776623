<template>
  <v-container class="content-page">
    <title-header-action
      :hasActionPermission="canRegisterNewScreening"
      btnLabel="Registrar Item"
      :btnAction="goToRegisterScreening"
    />
    <v-card class="table-page pt-7">
      <div class="table-page d-flex flex-column justify-space-between">
        <div>
          <!--filters -->
          <template v-if="showFilters">
            <div class="row px-5 d-flex justify-end">
              <v-col cols="12" sm="5" md="3" v-if="userIsAdmin">
                <v-select
                  :items="crcs"
                  item-text="description"
                  item-value="id"
                  outlined
                  @change="setFilter('crcId', $event)"
                  color="secondary-color-1"
                  label="Todos CRCs"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="5" md="3">
                <v-select
                  :items="statusSelect"
                  item-text="description"
                  item-value="id"
                  outlined
                  @change="setFilter('statusId', $event)"
                  color="secondary-color-1"
                  label="Status"
                ></v-select>
              </v-col>

              <v-col cols="9" sm="5" md="3">
                <v-text-field
                  @input="setFilterDebounced('search', $event)"
                  solo
                  flat
                  full-width
                  color="secondary-color-1"
                  background-color="white-1"
                  placeholder="Buscar (Ex: Doador/Parceiro...)"
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>

              <v-col cols="3" sm="2" md="1">
                <report-options
                  fileName="Lotes"
                  :header="headerReport"
                  :body="batchs"
                />
              </v-col>
            </div>
            <v-divider></v-divider>
          </template>

          <template v-if="!batchs.length">
            <div class="pt-10">
              <data-not-found
                v-if="!filterPristine"
                title="Lote não encontrado."
                :imgSrc="require('@/assets/lote.svg')"
              />

              <data-not-found
                v-if="filterPristine"
                title="Você ainda não possui nenhum lote registrado"
                btnLabel="Cadastrar o primeiro lote"
                :btnAction="goToRegisterScreening"
                :imgSrc="require('@/assets/lote.svg')"
              />
            </div>
          </template>

          <template v-if="batchs.length">
            <v-data-table
              :headers="headers"
              :items="batchs"
              :options.sync="dataTableOptions"
              :server-items-length="tableInfo.totalItems"
              hide-default-footer
            >
              <template v-slot:[`item.id`]="{ item }">
                <screening-menu-item :item="item" />
              </template>
            </v-data-table>
          </template>
        </div>

        <div v-if="batchs.length">
          <pagination
            :currentPage="tableInfo.currentPage"
            :totalPages="tableInfo.totalPages"
            :itemsPerPage="tableInfo.itemsPerPage"
            @currentPageChanged="handleCurrentPageChange($event)"
            @itemsPerPageChanged="handleItemsPerPageChange($event)"
          />
        </div>
      </div>
    </v-card>
  </v-container>
</template>
<script>
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import TableHandler from "@/modules/core/mixins/tableHandler";
  import DataNotFound from "@/modules/core/views/components/DataNotFound";
  import ReportOptions from "@/modules/core/views/components/ReportOptions";
  import TitleHeaderAction from "@/modules/core/views/components/TitleHeaderAction";
  import Pagination from "@/modules/core/views/components/Pagination";
  import ScreeningMenuItem from "@/modules/stock/batch/views/components/ScreeningMenuItem";
  import { commonProfiles, profiles } from "@/modules/core/enums/profiles.enum";
  import { BatchStatus } from "@/modules/stock/batch/enums/batchStatus.enum";

  import { mapGetters, mapActions } from "vuex";

  export default {
    name: "Screening",

    components: {
      ScreeningMenuItem,
      DataNotFound,
      ReportOptions,
      TitleHeaderAction,
      Pagination
    },

    mixins: [MasksMixin, TableHandler],

    data: () => ({
      menu1: null,
      dates: [],
      headers: [
        {
          text: "Lote",
          align: "start",
          value: "batchNumber"
        },
        { text: "Data registro", value: "screeningInitAt" },
        { text: "Doador/Parceiro", value: "sourceName" },
        { text: "Peso desmanche", value: "weightTotalUnmanufacturing" },
        { text: "Peso recondicionamento", value: "weightTotalReconditioning" },
        { text: "Pesagem total", value: "weightTotal" },
        { text: "Status", value: "status" },
        { value: "id" }
      ],

      headerReport: [
        {
          header: "Lote",
          dataKey: "batchNumber"
        },
        { header: "Data registro", dataKey: "screeningInitAt" },
        { header: "Doador/Parceiro", dataKey: "sourceName" },
        { header: "Peso desmanche", dataKey: "weightTotalUnmanufacturing" },
        {
          header: "Peso recondicionamento",
          dataKey: "weightTotalReconditioning"
        },
        { header: "Pesagem total", dataKey: "weightTotal" },
        { header: "Status", dataKey: "status" }
      ],

      batchs: [],

      crcs: [
        {
          id: null,
          description: null
        }
      ],

      status: [
        {
          id: null,
          description: null
        }
      ]
    }),
    created() {
      this.initFilter("search", "");
      this.initFilter("crcId", null);
      this.initFilter("statusId", null);
    },
    mounted() {
      this.fetchBatchs();
      if (this.userIsAdmin) {
        this.fetchCrcs();
      }
    },

    computed: {
      ...mapGetters({
        userIsAdmin: "user/getUserIsAdmin",
        userRoleId: "user/getUserRoleId",
        routes: "getRoutes"
      }),

      statusSelect() {
        return [
          { description: "Todos", id: "" },
          ...Object.values(BatchStatus)
        ];
      },

      profileSelect() {
        const all = {
          All: {
            description: "Todos",
            id: ""
          }
        };

        if (this.userIsAdmin) {
          return { ...all, ...profiles };
        }

        return { ...all, ...commonProfiles };
      },
      showFilters() {
        if (this.batchs?.length) {
          return true;
        }
        return !this.batchs.length && !this.filterPristine;
      },
      canRegisterNewScreening() {
        return this.routes?.some(
          route =>
            route.name === "new-screening" &&
            route?.meta?.permissions.includes(this.userRoleId)
        );
      }
    },
    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("crc", ["getCRCs"]),
      ...mapActions("stock/batch", ["getBatchs"]),

      goToRegisterScreening() {
        this.$router.push({ name: "new-screening" });
      },

      async fetchBatchs() {
        try {
          const payload = this.getPayloadData();
          payload.statusId = this.filters.statusId;
          payload.filterDemanufacturingItems = true;
          const { data, meta } = await this.getBatchs(payload);
          this.setTableInfo(meta);
          this.batchs = this.formatBatchsData(data);
        } catch (error) {
          this.toggleSnackbar({
            text:
              error?.data?.message ??
              `Ocorreu um erro: ${error}. Tente novamente`,
            type: "error"
          });
        }
      },

      formatBatchsData(batchs) {
        return batchs.map(batch => {
          return {
            id: batch.id,
            batchNumber: batch.batchNumber,
            screeningInitAt: this.parseDate(
              batch.screeningInitAt,
              "dd/MM/yyyy"
            ),
            screeningEndAt: batch.screeningEndAt ?? null,
            sourceName: batch.sourceName ?? "Não informado",
            crc: batch.crcId,
            weightTotal: this.safeParseWeight(batch.weightTotal),
            weightTotalUnmanufacturing: this.safeParseWeight(
              batch.weightTotalUnmanufacturing
            ),
            weightTotalReconditioning: this.safeParseWeight(
              batch.weightTotalReconditioning
            ),
            status: batch.status ?? "Aguardando Triagem",
            statusId: batch.statusId
          };
        });
      },

      async fetchCrcs() {
        try {
          const { data } = await this.getCRCs({ limit: 100 });
          this.crcs = [
            {
              description: "Todos",
              id: ""
            },
            ...data
          ];
        } catch (error) {
          this.toggleSnackbar({
            text:
              error?.data?.message ??
              `Ocorreu um erro: ${error}. Tente novamente`,
            type: "error"
          });
        }
      },

      updateData() {
        this.fetchBatchs();
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/buttons";
  @include btn-primary();
</style>
